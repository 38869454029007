<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      While <a href="https://steemlogin.com/sign/account-witness-vote?witness=exnihilo.witness&approve=1"
        target="_blank" rel="noopener">voting</a> for our witness you get rewarded everyday for supporting our witness
      node.
    </p>
    <div v-if="lastVest">
      <h3>Average Reward Distribution Per day : {{parseFloat(toSp(lastVest)/100*percentage).toFixed(3)}} STEEM</h3>
      <ul>
        <li>Based on {{percentage}}% reward distribution with a total of {{parseFloat(toSp(lastVest)).toFixed(3)}} SP
          produced in
          the last day</li>
      </ul>
      @exnihilo.witness current SP: {{parseFloat(toSp(accountvest)).toFixed(3)}}
    </div>


    <h3>Calculate your rewards</h3>
    <ul>
      <li><input placeholder="@username" v-model="username" /></li>
      <button @click="calculate()">Confirm</button>
    </ul>
    <h3>Your account</h3>
    <ul>
      <li>Current SP: {{parseFloat(usersp).toFixed(3)}} - Current SP with Proxies: {{parseFloat(userfullsp).toFixed(3)}}
      </li>
    </ul>
    <div v-if="userfullsp">
      <h3>Your rewards!</h3>
      <ul>
        <li>Per day: {{parseFloat((toSp(lastVest)/100*percentage) / totalvotesp * userfullsp).toFixed(3)}} STEEM</li>
        <li>Per month: ~{{parseFloat(((toSp(lastVest)/100*percentage) / totalvotesp * userfullsp)*30).toFixed(3)}}STEEM
        </li>
        <li>Per year: ~{{parseFloat(((toSp(lastVest)/100*percentage) / totalvotesp * userfullsp)*365).toFixed(3)}} STEEM
        </li>
      </ul>
    </div>

    <h3>Vote for us and start to get rewarded in 24 Hours!</h3>
    <ul>
      <li><a href="https://steemlogin.com/sign/account-witness-vote?witness=exnihilo.witness&approve=1" target="_blank"
          rel="noopener">I vote for exnihilo.witness!</a></li>
    </ul>
    <h3>Are you a developer or in need of a reliable Steem Node?</h3>
    <ul>
      <li>Feel free to use our json-rpc : https://api.exnihilo.witness</li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import steem from "steem"
import { Client } from "dsteem"
const client = new Client('https://api.steemit.com');
console.log(client)

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      percentage: 50,
      username: "exnihilo.witness",
      lastrewards: 0,
      lastVest: null,
      props: null,
      vestprop: 0,
      totalvestprop: 0,
      resultprop: 0,
      totalvotesp: 0,
      accountvest: 0,
      usersp: 0,
      userfullsp: 0,
      witness_sp: 0
    };
  },
  mounted() {
    let dateOffset = (24 * 60 * 60 * 1000)
    let self = this
    let newD = new Date()
    newD.setHours(0, 0, 0, 0)
    console.log(newD)
    let startDate = (newD.getTime() - (dateOffset * 2)) / 1000
    newD.setHours(23, 59, 0, 0)
    let endDate = (newD.getTime() - (dateOffset * 1)) / 1000
    axios.get('https://sds.steemworld.org/rewards_api/getRewardsSums/producer_reward/exnihilo.witness/' + startDate + '-' + endDate)
      .then(function (response) {
        // handle success
        self.lastVest = response.data.result.vests
        client.database.getDynamicGlobalProperties().then((props) => {
          self.vestprop = self.vestAmount(props.total_vesting_fund_steem)
          self.totalvestprop = self.vestAmount(props.total_vesting_shares)
          self.spervest = self.vestprop / (self.totalvestprop / 1e6)
          self.props = props
          client.database.getAccounts(['exnihilo.witness']).then((accounts) => {
            console.log(accounts)
            const account = accounts[0]
            self.accountvest = self.vestAmount(account.vesting_shares)
            self.witness_sp = self.accountvest / 1e3 * self.spervest / 1000;
            steem.api.getWitnessByAccount('exnihilo.witness', function (err, witnessaccount) {
              console.log(err, witnessaccount)
              self.totalvotesp = ((witnessaccount.votes / 1000000000) / 1e3 * self.spervest)
            })
          })

        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });


  },
  computed: {


  },
  methods: {
    vestAmount(value) {
      return Number(value.split(' ')[0])
    },
    async calculate() {
      const self = this
      client.database.getAccounts([this.username.replace('@', '')]).then((accounts) => {
        const account = accounts[0]
        console.log(account)
        let account_vest = self.vestAmount(account.vesting_shares)
        let account_sp = account_vest / 1e3 * self.spervest / 1000
        self.usersp = account_sp
        account.proxied_vsf_votes.forEach(element => {
          if (element != 0) {
            console.log(element)
            account_sp += Number(element) / 1e3 * self.spervest / 1000000000
          }
        });
        self.userfullsp = account_sp
      })
    },
    calcSp(sp) {
      return sp / 1e3 * this.resultprop / 1000
    },
    toSp(vest) {
      return vest / 1e3 * this.spervest / 1000
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
