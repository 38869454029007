<template>
  <div id="app">
    <img alt="logo" src="https://steemitimages.com/p/3zpz8WQe4SNGWd7TzozjPgq3rggennavDx3XPY35pEAVnpqHS58iZtWUfAyk7CaD1ZQ7QonnfhK298BF4CHkE3Fa68TF6zePdYkpWWBX4X2WFhtDsdBh3k1s6BzKfYsHxqSvU7Nwr2qQKaXBqpHC?width=128&height=128">
    <HelloWorld msg="Exnihilo Voting Reward Calculator"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
